import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthService } from './auth.service';
import {
  Announcement,
  AnnouncementRead,
} from '@/app/core/models/announcement.model';

@Injectable({ providedIn: 'root' })
export class AnnouncementsService {
  http = inject(HttpClient);
  authService = inject(AuthService);

  getAnnouncements(username?: string) {
    return this.http.get<Announcement[]>(
      '/api/Announcements',
      username ? { params: { username } } : {},
    );
  }
  markAsRead(announcementRead: AnnouncementRead) {
    return this.http.post('/api/AnnUserReadLog', announcementRead);
  }
  markKeepRead(AnnouncementID: number) {
    return this.http.delete('/api/AnnUserReadLog', {
      params: { AnnouncementID },
    });
  }
  getAnnouncement(announcementId: number) {
    return this.http.get<Announcement>(`/api/Announcements/${announcementId}`);
  }
  deleteAnnouncement(announcementId: number) {
    return this.http.delete(`/api/Announcements/${announcementId}`);
  }
  saveAnnouncement(announcement: Announcement) {
    if (announcement.ANNOUNCEMENT_ID) {
      return this.http.put(
        `/api/Announcements/${announcement.ANNOUNCEMENT_ID}`,
        announcement,
      );
    }
    return this.http.post('/api/Announcements', announcement);
  }
}
